import {
  ContentLayout,
  DeleteModal,
  ServerPaginatedTable,
} from "../../../shared";
import * as React from "react";
import { Button, Chip, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputControl from "../../../shared/components/InputControl";
import { sortItems, sortStatus } from "../../../utils";
import { useCouponList } from "../hooks";
import moment from "moment";

export const CouponsList = () => {
  const navigate = useNavigate();
  const {
    state,
    globalData,
    onChangeFilter,
    resetFilters,
    deleteModal,
    handleDeleteCoupons,
    handleDuplicateCoupons,
    handlePlayPause,
    isCreateVisible,
    isDeletable,
    setCurentTab,
    gotoPage,
    setPageSize,
    handleSort,
    onSeachClear,
  } = useCouponList({ isCoupons: true });
  const ActionButtons = ({ status, deleteAction, id, currentTabAction }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        {status === 3 && (
          <img
            onClick={() => handlePlayPause(id)}
            src="/icons/ic_play_icon.svg"
          />
        )}
        {status === 2 && (
          <img onClick={() => handlePlayPause(id)} src="/icons/ic_play2.svg" />
        )}
        <img
          onClick={() => handleDuplicateCoupons(id)}
          src="/icons/ic_data.svg"
        />
        {isDeletable && (
          <img alt="delete" onClick={deleteAction} src="/icons/ic_delete.svg" />
        )}
        <img
          alt="details"
          src="/icons/arrow_forward.svg"
          onClick={currentTabAction}
        />
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Label",
        accessor: "label",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Type",
        accessor: "location_type",
        Cell: ({ value, row }) => {
          const type = globalData?.coupons?.location_type?.find(
            (i) => i.value === value
          );
          const stores = row.original.stores;
          return type?.value === 1 ? (
            <Tooltip
              placement="right"
              title={stores?.map((store) => {
                return (
                  <span>
                    <p>{store.name}</p>
                  </span>
                );
              })}
            >
              <Typography
                variant="subtitle"
                fontWeight={400}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                {type.label}
              </Typography>
            </Tooltip>
          ) : (
            type.label
          );
        },
      },
      {
        Header: "Start Date",
        accessor: "start_from",
      },
      {
        Header: "End Date",
        accessor: "end_till",
      },
      {
        Header: "Created Date",
        accessor: "created_at",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
        Cell: ({ value }) => {
          return moment(value).format("YYYY-MM-DD");
        },
      },
      {
        Header: "Status",
        accessor: "active",
        sortType: (prev, curr, columnId) => {
          return sortStatus(
            globalData.coupons?.status.find(
              (e) => e.value === prev.original[columnId]
            ).label,
            globalData.coupons?.status.find(
              (e) => e.value === curr.original[columnId]
            ).label
          );
        },
        Cell: ({ value }) => {
          return (
            <>
              {value === 3 && (
                <Chip label={"Pause"} color="success" size="small" />
              )}
              {value === 2 && (
                <Chip
                  label={"Active"}
                  color="success"
                  variant="outlined"
                  size="small"
                  disabled={false}
                />
              )}
              {value === 4 && (
                <Chip
                  label={"Ended"}
                  color="success"
                  variant="outlined"
                  size="small"
                  disabled={"filled"}
                />
              )}
              {value === 1 && (
                <Chip
                  label={"Draft"}
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{ bgcolor: "#1560D414" }}
                  disabled={false}
                />
              )}
            </>
          );
        },
      },
      {
        Header: "Used coupons",
        accessor: "used_coupon_count",
        Cell: ({ row }) => {
          let val = row.original
          return (
            <>
              <Chip
                label={
                  val.max_redeem_count === null ?
                    `${val.used_coupon_count}/∞` : `${val.used_coupon_count}/${val.total_coupon_redeem_count}`
                }
                color="primary"
                size="small"
                sx={{
                  bgcolor: "#001A52",
                  "& .MuiChip-label": {},
                }}
                disabled={false}
              />
            </>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return (
            <ActionButtons
              status={row.active}
              deleteAction={() => {
                deleteModal(row.id);
              }}
              currentTabAction={() => {
                setCurentTab("CouponseDetails", "1");
                navigate(`details/${row.id}`);
              }}
              id={row.id}
            />
          );
        },
      },
    ],
    [globalData]
  );

  return (
    <>
      <ContentLayout
        title="Coupons"
        rightContent={
          <>
            {isCreateVisible && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/coupons/create")}
              >
                Add Coupon
              </Button>
            )}
          </>
        }
      >
        <ServerPaginatedTable
          columns={columns}
          data={state?.coupons ?? []}
          onRowLink={(e) => {
            return `details/${e.id}`;
          }}
          onRowAction={() => {
            setCurentTab("CouponseDetails", "1");
          }}
          key={state.sortBy}
          isLoading={state?.isBusy}
          defaultSearch={false}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pagination={state.pagination}
          handleSort={handleSort}
          sortByGlobal={state.sortBy}
          filterContent={
            <>
              <InputControl
                type="table-search"
                name="searchText"
                placeholder="Search"
                size="small"
                value={state.filterApplied.searchText}
                onChange={(e) => onChangeFilter(e)}
                onClear={onSeachClear}
              />
              <InputControl
                type="select"
                name="status"
                label="Status"
                optionValue={globalData?.coupons?.status ?? []}
                width="250px"
                onChange={(e) => onChangeFilter(e)}
                value={state.filterApplied.status}
              />
              <Button
                type="button"
                size="medium"
                variant="text"
                color="gray"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={(e) => resetFilters(e)}
              >
                <CloseIcon />
              </Button>
            </>
          }
        />
      </ContentLayout>
      <DeleteModal
        open={state.isOpen}
        handleClose={() => deleteModal()}
        onConfirm={() => handleDeleteCoupons()}
      />
    </>
  );
};
