/* eslint-disable no-throw-literal */
import React from "react";
import { useImmer } from "use-immer";
import { useDropdownApis } from "../../../shared";
import moment from "moment";
import { Chip, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import {
  downloadPaymentMediaReportApi,
  getPaymentMediaReportApi,
} from "../api";
import { convertDate, errorMessage, successMessage } from "../../../utils";
import { useDebouncedCallback } from "use-debounce";
import { AppContext, TableStateContext } from "../../../store";

// Table name for the payment media report
const tableName = "paymentMediaReport";

// Current date for reference
const currentDate = new Date();

/**
 * Default date object for the calendar filter.
 * Contains the initial state for the date selection.
 *
 * @type {Object}
 * @property {boolean} isCalenderOpen - Indicates if the calendar is open.
 * @property {Array<Object>} value - The selected date range.
 * @property {Array<Object>} showDate - Dates to be displayed.
 */
const defaultDateObject = {
  isCalenderOpen: false,
  value: [
    {
      startDate: currentDate,
      endDate: currentDate,
      key: "selection",
    },
  ],
  showDate: [],
};

/**
 * Current day date object for display.
 * Represents the date range for the current day.
 *
 * @type {Array<Object>}
 */
const currentDayShowDate = [
  {
    startDate: moment(currentDate).toDate(),
    endDate: moment(currentDate).toDate(),
    key: "selection",
  },
];

/**
 * Current day applied filters for date filtering.
 * Used to show the filter applied for the current date.
 *
 * @type {Array<Object>}
 */
const currentDayAppliedFilters = [
  {
    label: `${convertDate({ date: currentDate })} to ${convertDate({
      date: currentDate,
    })} `,
    type: "order date",
  },
];

/**
 * Initial filter object containing all filter settings.
 *
 * @type {Object}
 * @property {Object} list - Holds the lists of various filters.
 * @property {Object} date - Default date object for the date filter.
 * @property {Array<Object>} appliedFilters - Currently applied filters.
 * @property {boolean} reset - Indicates if filters need to be reset.
 * @property {boolean} isFilterApplied - Indicates if any filters are applied.
 */
const initialFilter = {
  list: {
    order_status: [],
    store: [],
    sales_type: [],
    payment_media: [],
    terminal_name: [],
    sales_channel: [],
  },
  date: defaultDateObject,
  appliedFilters: [],
  reset: false,
  isFilterApplied: true,
};

/**
 * Default pagination settings for the payment media report.
 *
 * @type {Object}
 * @property {number} pageIndex - The current page index.
 * @property {number} pageSize - The number of items per page.
 * @property {number} pageCount - Total number of pages.
 * @property {number} total - Total number of items.
 * @property {boolean} hasMorePages - Indicates if there are more pages to load.
 * @property {boolean} lastPage - Indicates if the current page is the last page.
 */
const defaultPagination = {
  pageIndex: 1,
  pageSize: 50,
  pageCount: 0,
  total: 0,
  hasMorePages: true,
  lastPage: false,
};

export const usePaymentMediaReport = () => {
  // Retrieve data from dropdown APIs and app context
  const {
    state: { stores, terminals },
  } = useDropdownApis({
    isTerminalsList: true,
    isStoreList: true,
  });
  const {
    appState: {
      globalData: { sale_channels, order_statues, sale_type, payment_types },
    },
  } = React.useContext(AppContext);

  const { filterState, filterStateDispatch } =
    React.useContext(TableStateContext);

  /**
   * Defines the columns for the payment media report table.
   * Each column specifies its header, accessor, and custom rendering logic if needed.
   *
   * @type {Array<Object>}
   */
  const tableColumns = React.useMemo(
    () => [
      {
        Header: "Order Date",
        accessor: "order_date",
        Cell: ({ value }) => {
          return (
            <Typography
              sx={{
                width: "100px",
                wordBreak: "break-word",
              }}
            >
              {value}
            </Typography>
          );
        },
      },
      {
        Header: "Order Number",
        accessor: "order_number",
      },
      {
        Header: "Store",
        accessor: "store",
        id: "store_name",
      },
      {
        Header: "Sales Channel",
        accessor: "sales_channel",
        id: "channel_name",
      },
      {
        Header: "Sales Type",
        accessor: "sales_type",
      },
      {
        Header: "Order Status",
        accessor: "order_status",
        id: "status",
        Cell: ({ row: { original } }) => {
          const chipColor = [
            "Placed",
            "Confirmed",
            "Completed",
            "Ready",
          ].includes(original.order_status)
            ? "success"
            : [
                "Processing Payment",
                "ON HOLD",
                "Partially Refund",
                "Ready",
              ].includes(original.order_status)
            ? "warning"
            : "error";
          return (
            <Chip
              label={original.order_status}
              color={chipColor}
              variant="outlined"
              size="small"
              sx={{
                border: "none !important",
              }}
            />
          );
        },
      },
      {
        Header: "Order Total",
        accessor: "order_total",
        id: "grand_total",
        Cell: ({ row: { original } }) => {
          return <span>${original.order_total}</span>;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          return <span>${original.amount}</span>;
        },
      },
      {
        Header: "Payment Media",
        accessor: "payment_media",
        id: "payment_type",
      },
      {
        Header: "Terminal Name",
        accessor: "terminal_name",
        Cell: ({ row: { original } }) => {
          return (
            <Typography>
              {original.terminal_name ? original.terminal_name : "--"}
            </Typography>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ArrowForward />;
        },
      },
    ],
    []
  );

  // Retrieve the filter applied for the specific table from the filter state context
  const storedFilter = filterState.params[tableName]?.filterApplied;
  const storedPagination = filterState.params[tableName]?.pagination;
  const storedSortBy = filterState.params[tableName]?.sortBy;
  const storedSearch = filterState.params[tableName]?.search;
  // Format the date values from the stored filter context, if available
  const storedDateValue = storedFilter
    ? [
        {
          // Convert the start and end dates from the stored filter to JavaScript Date objects
          startDate: moment(storedFilter.date.value[0].startDate).toDate(),
          endDate: moment(storedFilter.date.value[0]?.endDate).toDate(),
          key: "selection", // Use a fixed key for selection
        },
      ]
    : defaultDateObject.value; // Fallback to default date values if no filter is applied

  const [state, setState] = useImmer({
    paymentMediaReport: {
      columnOptions: tableColumns,
      isLoading: true,
      list: [],
      sortBy: storedSortBy ?? [],
      search: storedSearch ?? "",
      pagination: storedPagination ?? defaultPagination,
      export: {
        isExporting: false,
      },
    },
    tableDrawer: {
      open: false,
    },
    filter: storedFilter
      ? {
          ...storedFilter,
          appliedFilters:
            storedFilter.date.showDate.length === 0
              ? currentDayAppliedFilters
              : storedFilter.appliedFilters,
          date: {
            ...storedFilter.date,
            value:
              storedFilter.date.showDate.length === 0
                ? currentDayShowDate
                : storedDateValue,
            showDate:
              storedFilter.date.showDate.length === 0
                ? currentDayShowDate
                : storedDateValue,
          },
        }
      : {
          ...initialFilter,
          date: {
            ...initialFilter.date,
            showDate: currentDayShowDate,
          },
          appliedFilters: currentDayAppliedFilters,
        },
  });

  const breadcrumbsLink = [
    { name: "Dashboard", href: "/" },
    { name: "Payment Media Report", href: "" },
  ];

  //================================================================================= API SECTION ===================================================================================
  /**
   * Retrieves payment media logs with optional filtering, sorting, and pagination.
   *
   * @param {Object} options - The options for retrieving logs.
   * @param {string} [options.filter] - Optional filter string to narrow down results.
   * @param {string} [options.sortBy] - The field to sort the results by.
   * @param {boolean} [options.ascending=true] - Sort order; true for ascending, false for descending.
   * @param {number} [options.page=1] - The page number for pagination.
   * @param {number} [options.pageSize=10] - The number of logs per page.
   * @returns {Promise<Object[]>} A promise that resolves to an array of payment media logs.
   *
   * @throws {Error} If there is an issue retrieving the logs.
   */
  const getPaymentMediaLog = async ({
    pageIndex,
    pageSize,
    search = "",
    sortOptions = null,
    ...params
  }) => {
    triggerTableLoading(true);
    try {
      const response = await getPaymentMediaReportApi({
        pageIndex,
        pageSize,
        search,
        ...params,
      });
      const { success, message } = response;
      const responseData = response.data;

      if (success && responseData) {
        // Update table context
        filterStateDispatch({
          type: "SET_DATA",
          page: tableName,
          data: {
            filterApplied: state.filter,
            pagination: responseData.pagination,
            search,
            sortBy: sortOptions ? sortOptions : state.paymentMediaReport.sortBy,
          },
        });
        setState((draft) => {
          draft.paymentMediaReport.pagination = responseData.pagination;
          draft.paymentMediaReport.list = responseData.orders;
          draft.filter.isFilterApplied = true;
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ??
        "Payment media report fetching failed!!";

      errorMessage(message);
    } finally {
      triggerTableLoading(false);
    }
  };

  /**
   * Exports the payment media report based on the specified columns.
   *
   * @async
   * @param {Array<string>} columns - An array of column names to include in the exported report.
   * @returns {Promise<void>} A promise that resolves when the export is completed.
   *
   * @throws {Object} Throws an error object if the export fails, containing a message.
   */
  const exportPaymentMediaReport = async (columns) => {
    triggerExportButtonLoading(true);
    try {
      const response = await downloadPaymentMediaReportApi(columns);
      const { success, message } = response;
      if (success) {
        successMessage(message);
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.message ??
        "Payment media report exporting failed!!";

      errorMessage(message);
    } finally {
      triggerExportButtonLoading(false);
    }
  };

  //================================================================================= UTIL FUNCTION SECTION ===================================================================================
  /**
   * Sets the loading state of the export button for the payment media report.
   *
   * @param {boolean} status - The loading status to set (true for loading, false otherwise).
   */
  const triggerExportButtonLoading = (status) => {
    setState((draft) => {
      draft.paymentMediaReport.export.isExporting = status;
    });
  };

  /**
   * Sets the loading state of the payment media report table.
   *
   * @param {boolean} status - The loading status to set (true for loading, false otherwise).
   */
  const triggerTableLoading = (status) => {
    setState((draft) => {
      draft.paymentMediaReport.isLoading = status;
    });
  };

  /**
   * Navigates to a specific page of the payment media report.
   *
   * @async
   * @param {number} pageIndex - The index of the page to navigate to.
   * @returns {Promise<void>} A promise that resolves when the page navigation is complete.
   */
  const gotoPage = async (pageIndex) => {
    const {
      paymentMediaReport: {
        pagination: { pageSize },
        sortBy,
      },
      filter: { appliedFilters },
    } = state;
    const formData = { pageIndex, pageSize };

    setState((draft) => {
      draft.paymentMediaReport.pagination.pageIndex = pageIndex;
    });

    await appendSortAndFilterProperties({
      formData,
      appliedFilters,
      sortOptions: sortBy,
    });
    getPaymentMediaLog(formData);
  };

  /**
   * Sets the page size for the payment media report and resets the page index to 1.
   *
   * @async
   * @param {Event} event - The event object from the page size selection.
   * @returns {Promise<void>} A promise that resolves when the page size change is complete.
   */
  const setPageSize = async (event) => {
    const { value } = event.target;
    const pageIndex = 1;
    const formData = { pageIndex, pageSize: value };
    const {
      paymentMediaReport: { sortBy },
      filter: { appliedFilters },
    } = state;

    setState((draft) => {
      draft.paymentMediaReport.pagination.pageIndex = pageIndex;
      draft.paymentMediaReport.pagination.pageSize = +value;
    });

    await appendSortAndFilterProperties({
      formData,
      appliedFilters,
      sortOptions: sortBy,
    });
    getPaymentMediaLog(formData);
  };

  /**
   * A debounced callback for searching the payment media logs.
   * The search triggers after a delay of 1000ms.
   *
   * @param {string} value - The search term to filter the payment media logs.
   */
  const debouncedCallback = useDebouncedCallback((value) => {
    const { pageSize } = state.paymentMediaReport.pagination;
    const pageIndex = 1;
    setState((draft) => {
      draft.paymentMediaReport.pagination.pageIndex = pageIndex;
    });

    getPaymentMediaLog({ pageIndex, pageSize, search: value });
  }, 1000);

  /**
   * Handles the search input change for the payment media report table.
   *
   * @param {Event} event - The event object from the search input.
   */
  const handleTableSearch = (event) => {
    const { value } = event.target;
    setState((draft) => {
      draft.paymentMediaReport.search = value;
    });
    debouncedCallback(value);
  };

  /**
   * Toggles the visibility of the column configuration sidebar.
   */
  const toggleColumnConfigSidebar = () => {
    setState((draft) => {
      draft.tableDrawer.open = !draft.tableDrawer.open;
    });
  };

  /**
   * Opens or closes the date range selection calendar.
   *
   * @param {boolean} event - The state to set for the calendar (true to open, false to close).
   */
  const handleOpenDateRangeSelection = (event) => {
    setState((draft) => {
      draft.filter.date.isCalenderOpen = event;
    });
  };

  /**
   * Applies the selected date range as a filter for the payment media report.
   */
  const handleDateChangeApply = () => {
    const appliedFilterKey = "order date";

    setState((draft) => {
      const dateString = {
        label: `${convertDate({
          date: state.filter.date.value[0].startDate,
        })} to ${convertDate({ date: state.filter.date.value[0].endDate })}`,
        type: appliedFilterKey,
      };

      const existedItem = draft.filter.appliedFilters.find((item) => {
        return item.type === appliedFilterKey;
      });

      const updatedFilter = draft.filter.appliedFilters.map((item) => {
        if (item.type === appliedFilterKey || existedItem?.type === item.type) {
          return dateString;
        } else {
          return item;
        }
      });

      draft.filter.appliedFilters = existedItem
        ? updatedFilter
        : updatedFilter.concat([dateString]);
      draft.filter.date.showDate = state.filter.date.value;
      draft.filter.date.isCalenderOpen = false;
    });
  };

  /**
   * Appends sorting and filtering properties to the provided formData object based on applied filters and sort options.
   *
   * @async
   * @param {Object} params - The parameters for appending sort and filter properties.
   * @param {Object} params.formData - The formData object to which sorting and filtering properties will be added.
   * @param {Array<Object>} [params.appliedFilters=null] - An optional array of applied filters to process.
   * @param {Array<Object>} [params.sortOptions=null] - An optional array of sort options to apply.
   * @returns {Promise<void>} A promise that resolves when the properties are appended.
   */
  const appendSortAndFilterProperties = async ({
    formData,
    appliedFilters = null,
    sortOptions = null,
  }) => {
    // Process applied filters
    if (appliedFilters && appliedFilters.length > 0) {
      appliedFilters.forEach((filter) => {
        const key = filter.type.replace(" ", "_");
        const value = filter.item
          ? filter.item.id || filter.item.value
          : filter.label;

        // Map filter keys to actual database keys
        const actualKey =
          key === "order_status"
            ? "status"
            : key === "store"
            ? "store_id"
            : key === "payment_media"
            ? "payment_type"
            : key === "terminal_name"
            ? "terminal_id"
            : key === "sales_channel"
            ? "channel"
            : key;

        switch (actualKey) {
          case "order_date": {
            // Split date range into start and end dates
            const dates = value.split("to");
            formData["start_date"] = dates[0];
            formData["end_date"] = dates[1];
            break;
          }
          default: {
            // Initialize the key with an array if it doesn't exist
            !formData[actualKey] && (formData[actualKey] = []);

            // Add the value to the array for the key
            formData[actualKey].push(value);
            break;
          }
        }
      });
    }

    // Process sorting options
    if (sortOptions && sortOptions.length > 0) {
      const sortedItem = sortOptions[0];
      const sortedItemAccessor = sortedItem.id;

      // Append sort direction to formData
      Object.assign(formData, {
        [`sort[${sortedItemAccessor}]`]: sortedItem.desc ? "desc" : "asc",
      });
    }
  };

  /**
   * Formats the selected filters into a structured response.
   *
   * @param {Object} selectedFilters - An object containing selected filters.
   * @returns {Array<Object>} An array of formatted filter objects.
   */
  const formateFilterResponse = (selectedFilters) => {
    const response = Object.entries(selectedFilters)
      .flatMap((filter) => {
        if (filter[1].length > 0) {
          return filter[1].map((item) => {
            const label = item.label || item.name;
            return {
              type: filter[0].replace("_", " "),
              label,
              item,
            };
          });
        } else {
          return null;
        }
      })
      .filter((val) => val !== null);
    return response;
  };

  /**
   * Handles changes to filters, updating the state accordingly.
   *
   * @async
   * @param {Object} param - The filter change event.
   * @param {string} param.name - The name of the filter being changed.
   * @param {Array} param.value - The new value(s) for the filter.
   */
  const handleFilterChange = async ({ name, value }) => {
    if (name === "order_date") {
      setState((draft) => {
        draft.filter.date.value = value;
        draft.filter.isFilterApplied = false;
      });
    } else {
      setState((draft) => {
        const newFilter = formateFilterResponse({ [name]: value });

        const existedFilters = state.filter.appliedFilters.filter((filter) => {
          const filterName = filter.type.replace(" ", "_");
          return filterName !== name;
        });
        draft.filter.list[name] = value;
        draft.filter.appliedFilters = existedFilters.concat(newFilter);
        draft.filter.isFilterApplied = false;
      });
    }
  };

  /**
   * Resets all filters to their initial state and fetches the updated logs.
   *
   * @async
   */
  const handleRestAllFilter = async () => {
    const {
      pagination: { pageIndex, pageSize },
      sortBy,
    } = state.paymentMediaReport;
    const formData = { pageIndex, pageSize };

    setState((draft) => {
      draft.filter = initialFilter;
    });

    await appendSortAndFilterProperties({ formData, sortOptions: sortBy });
    getPaymentMediaLog(formData);
  };

  /**
   * Clears a specific filter from the applied filters and updates the state.
   *
   * @param {Object} filter - The filter to clear.
   * @param {string} filter.type - The type of the filter.
   * @param {Object} filter.item - The item associated with the filter.
   */
  const handleClearFilter = (filter) => {
    const filterType = filter.type.replace(" ", "_");

    const updatedAppliedFilter = state.filter.appliedFilters.filter((item) => {
      if (filterType === "order_date") {
        return filter.label !== item.label;
      } else {
        const deletedItemUniqueId = filter.item?.value || filter.item?.id;
        const itemUniqueId = item.item?.id || item.item?.value;

        return deletedItemUniqueId !== itemUniqueId;
      }
    });

    setState((draft) => {
      if (filterType === "order_date") {
        draft.filter.date = initialFilter.date;
      } else {
        const updatedFilter = state.filter.list[filterType].filter((item) => {
          const deletedItemUniqueId = filter.item?.value || filter.item?.id;
          const itemUniqueId = item.id || item.value;
          return deletedItemUniqueId !== itemUniqueId;
        });
        draft.filter.list[filterType] = updatedFilter;
      }

      draft.filter.appliedFilters = updatedAppliedFilter;
      draft.filter.isFilterApplied = false;
    });
  };

  /**
   * Handles sorting by updating the state and fetching the sorted logs.
   *
   * @async
   * @param {Array<Object>} sortOptions - The sorting options to apply.
   */
  const handleSortBy = async (sortOptions) => {
    const {
      paymentMediaReport: {
        pagination: { pageIndex, pageSize },
      },
      filter: { appliedFilters },
    } = state;
    const formData = { pageIndex, pageSize };

    setState((draft) => {
      draft.paymentMediaReport.sortBy = sortOptions;
    });

    await appendSortAndFilterProperties({
      formData,
      appliedFilters,
      sortOptions,
    });
    getPaymentMediaLog({ ...formData, sortOptions });
  };

  /**
   * Toggles the visibility of the column options for the payment media report.
   *
   * @param {Array<Object>} columns - The new column options to set.
   */
  const handleToggleColumnOption = (columns) => {
    setState((draft) => {
      draft.paymentMediaReport.columnOptions = columns;
    });
  };

  /**
   * Applies the current filters and pagination settings to fetch the updated payment media logs.
   *
   * @async
   * @returns {Promise<void>} A promise that resolves when the logs are fetched.
   */
  const handleApplyFilter = async () => {
    const pageIndex = 1;
    const {
      paymentMediaReport: {
        pagination: { pageSize },
        sortBy,
      },
      filter: { appliedFilters },
    } = state;
    const formData = { pageIndex, pageSize };

    setState((draft) => {
      draft.paymentMediaReport.pagination.pageIndex = pageIndex;
    });

    await appendSortAndFilterProperties({
      formData,
      appliedFilters,
      sortOptions: sortBy,
    });
    getPaymentMediaLog(formData);
  };

  /**
   * Exports the payment media report based on the visible columns and current filters.
   *
   * @async
   * @returns {Promise<void>} A promise that resolves when the export is completed.
   * @throws {Error} If no columns are visible for export.
   */
  const handleExportPaymentMediaReport = async () => {
    let formData = { columns: [] };
    const {
      filter: { appliedFilters },
      paymentMediaReport: { sortBy },
    } = state;

    // Identify hidden columns
    const hiddenColumns = state.paymentMediaReport.columnOptions.filter(
      (column) => !column.Header
    );

    // Collect visible column accessors for the export
    state.paymentMediaReport.columnOptions
      .filter(
        (column) =>
          !hiddenColumns.includes(column.accessor) &&
          !hiddenColumns.includes(column?.id) &&
          typeof column.accessor === "string"
      )
      .forEach((obj) => {
        // Use accessorKey for exports. For 'store', use column id as 'store_name'
        const accessorKey = obj.accessor === "store" ? obj.id : obj.accessor;
        formData.columns.push(accessorKey);
      });
    // Check if there are visible columns to export
    if (formData.columns.length > 0) {
      await appendSortAndFilterProperties({
        formData,
        appliedFilters,
        sortOptions: sortBy,
      });
      await exportPaymentMediaReport(formData);
    } else {
      errorMessage(
        "At least one column must be visible to export the report. Please adjust your view."
      );
    }
  };

  //================================================================================= USE EFFECT SECTION ===================================================================================
  // Effect that runs on component mount to set up the initial state for the payment media report.
  // It appends sorting and filtering properties and then fetches the payment media logs.
  React.useEffect(() => {
    const {
      paymentMediaReport: {
        pagination: { pageIndex, pageSize },
        sortBy,
      },
      filter: { appliedFilters },
    } = state;
    const formData = { pageIndex, pageSize };

    (async () =>
      await appendSortAndFilterProperties({
        formData,
        appliedFilters,
        sortOptions: sortBy,
      }))();
    getPaymentMediaLog(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state,
    tableColumns,
    filterList: {
      orderStatusList: order_statues,
      storeList: stores,
      salesTypeList: sale_type,
      paymentMediaList: payment_types,
      terminalList: terminals,
      salesChannelList: sale_channels,
    },
    breadcrumbsLink,
    gotoPage,
    setPageSize,
    handleSortBy,
    handleTableSearch,
    handleFilterChange,
    handleDateChangeApply,
    handleToggleColumnOption,
    toggleColumnConfigSidebar,
    handleOpenDateRangeSelection,
    handleApplyFilter,
    handleRestAllFilter,
    handleClearFilter,
    handleExportPaymentMediaReport,
  };
};
