import React from "react";

export const DayPicker = (prop) => {
  const { onDayPicker, data, options } = prop;

  const isSelected = (day) => {
    if (Array.isArray(data)) {
      if (data?.includes(day)) { return "selected"; }
    } else if (data === day) {
      return "selected"
    } else {
      return ''
    }
  };

  return (
    <div className="week-day-picker">
      {options?.map((data, index) => {
        return (
          <div
            key={index}
            className={`picker ${isSelected(data.value)}`}
            onClick={() => onDayPicker && onDayPicker(data.value)}
          >
            {data.label.substring(0, 3)}
          </div>
        );
      })}
    </div>
  );
};
