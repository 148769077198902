import { ContentLayout, CustomTable, HeaderBreadcrumbs, Loader } from "../../../shared";
import * as React from "react";
import { Chip, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useCoupons } from "../hooks/useCoupons";
import { sortItems } from "../../../utils";


export const CouponDetailsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useCoupons({
    isCoupons: false,
    isStore: false,
    couponsById: id,
    isBarcode: true
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Barcode",
        accessor: "coupon_barcode",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Coupon Code",
        accessor: "coupon_code",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Usage",
        accessor: "max_redeem_count",
        Cell: ({ value, row }) => {
          const { used_coupon = 0, max_redeem_count } = row.original;
          return (
            <>
              <Chip
                label={
                  max_redeem_count
                    ? `${used_coupon ?? 0}/${max_redeem_count ?? 0}`
                    : `${used_coupon ?? 0}/∞`
                }
                color="primary"
                size="small"
                sx={{
                  bgcolor: "#001A52",
                  "& .MuiChip-label": {},
                }}
                disabled={false}
              />
            </>
          );
        },
      },
      {
        Header: "Last Used Date & Time",
        accessor: "last_used_date",
        Cell: ({ value }) => {
          return value === null
            ? "--"
            : value;
        },
      },
      {
        Header: "Last Used Order",
        accessor: "order_id",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              {!row.original.last_used_order_number ? '--' : (
                <>
                  <div
                    className="table-taxt-and-icon-link cursor"
                    onClick={() =>
                      navigate(`/orders/details/${row.original.last_used_order_id}`)
                    }
                  >
                    <span className="link-text">
                      {row.original.last_used_order_number}
                    </span>
                  </div>
                </>
              )}
            </Stack>
          );
        },
      },
      {
        Header: "Last Used Customer",
        accessor: "last_used_customer",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap="12px" alignItems="center">
              {row.original.last_used_customer_id === null ? (
                '--'
              ) : (
                <div
                  className="table-taxt-and-icon-link cursor"
                  onClick={() =>
                    navigate(
                      `/customers/details/${row.original.last_used_customer_id}`
                    )
                  }
                >
                  <span className="link-text">
                    {row.original.last_used_customer}
                  </span>
                </div>
              )}
            </Stack>
          );
        },
      },

    ],
    [state.data.customer_type]
  );

  return (
    <>
      <ContentLayout
        title="Coupons List"
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Coupons", href: "/coupons" },
                { name: state.name, href: `/coupons/details/${id}` },
                { name: "Coupons List" },
              ]}
            />
          </>
        }
      >
        {state.isBusy ? (
          <Loader />
        ) : (
          <CustomTable
            defaultSearch={true}
            columns={columns}
            data={state?.barcodeViewList}
          />
        )}
      </ContentLayout>
    </>
  );
};
