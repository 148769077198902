import { Stack, Typography, Button, Box, Divider, Chip } from "@mui/material";
import React, { useRef } from "react";
import "react-quill/dist/quill.snow.css";
import { CustomDetailsList, Loader } from "../../../shared";
import { useNavigate, useParams } from "react-router";
import parse from "html-react-parser";
import moment from "moment";

export const CouponsGeneralDetails = (prop) => {
  const { state, globalData, isEditable } = prop;
  const { id } = useParams();
  const navigate = useNavigate();

  const AlbumArtRef = useRef();
  const conditionType = [
    { label: "AND", value: 1 },
    { label: "OR", value: 2 },
  ];

  const column1 = [
    {
      title: "Coupon Name",
      accessor: "name",
      accessor: (row) => {
        return (
          <Stack direction="row" gap={1}>
            {row?.name}
            <Chip
              label={
                row.max_redeem_count === null ?
                  `${row.used_coupon_count}/∞` : `${row.used_coupon_count}/${row.total_coupon_redeem_count}`
              }
              color="primary"
              size="small"
              sx={{
                bgcolor: "#001A52",
                "& .MuiChip-label": {},
              }}
              disabled={false}
            />
          </Stack>
        );
      },
    },

    {
      title: "Label",
      accessor: "label",
    },
    {
      title: "Description",
      accessor: (row) => {
        return <div>{row?.description && parse(row?.description)}</div>;
      },
    },
    {
      title: "Type",
      accessor: (row) => {
        return <div>{row?.location_type === 1 ? "LAM" : "Network"}</div>;
      },
    },
    {
      title: "Images",
      accessor: (row) => {
        return (
          <Stack height="250px" justifyContent="center" p="10px">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  bgcolor: "#F5F5F5",
                  borderRadius: 1,
                  objectFit: "cover",
                }}
                component="img"
                src={state.data.mobile_image}
              />

              <Box
                sx={{
                  width: "400px",
                  height: "200px",
                  bgcolor: "#F5F5F5",
                  borderRadius: 1,
                  objectFit: "cover",
                }}
                component="img"
                src={state.data.desktop_image}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      title: "Show promotion in coupon-listing",
      accessor: (row) => {
        return (
          <Stack
            height="48px"
            justifyContent="space-between"
            p="8px"
            direction="row"
          >
            <Stack direction="row" alignItems="center" gap="4px">
              <Typography>
                {row.show_in_list === 1 ? "Active" : "Inactive"}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },

    {
      title: "Start Date",
      accessor: "start_from",
      header: () => {
        return (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Date & Time
              </Typography>
            </Stack>
            <Divider />
          </>
        );
      },
    },
    {
      title: "End Date",
      accessor: "end_till",
    },
    {
      title: "Created Date",
      accessor: (row) => {
        return moment(row.created_at).format("YYYY-MM-DD");
      },
    },
    {
      title: "Last Updated",
      accessor: (row) => {
        return moment(row.updated_at).format("YYYY-MM-DD");
      },
    },
    {
      title: "Days",
      accessor: (row) => {
        return <>{row?.days_of_week?.join(", ")}</>;
      },
    },
    {
      title: "Start Time",
      accessor: "start_time",
    },
    {
      title: "End Time",
      accessor: "end_time",
    },

    {
      title: "Use in",
      accessor: "coupon_usage_type",
      header: () => {
        return (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Usage Restrictions
              </Typography>
            </Stack>
            <Divider />
          </>
        );
      },
      accessor: (row) => {
        return (
          <div>
            <>
              <Stack direction="row" gap={1}>
                {row.coupon_usage_type === 1 && (
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    label={"In Store"}
                    variant="filled"
                    size="small"
                  />
                )}
                {row.coupon_usage_type === 2 && (
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    label={"Online Ordering"}
                    variant="filled"
                    size="small"
                  />
                )}
                {row.coupon_usage_type === 3 && (
                  <Stack direction="row" spacing={1}>
                    <Chip
                      sx={{ borderRadius: "8px" }}
                      label={"Online Ordering"}
                      variant="filled"
                      size="small"
                    />
                    <Chip
                      sx={{ borderRadius: "8px" }}
                      label={"In Store"}
                      variant="filled"
                      size="small"
                    />
                  </Stack>
                )}

                {row.customer_type === 1 && (
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    label={"Member"}
                    variant="filled"
                    size="small"
                  />
                )}
                {row.customer_type === 2 && (
                  <Chip
                    sx={{ borderRadius: "8px" }}
                    label={"Guest"}
                    variant="filled"
                    size="small"
                  />
                )}
                {row.customer_type === 3 && (
                  <Stack direction="row" spacing={1}>
                    <Chip
                      sx={{ borderRadius: "8px" }}
                      label={"Guest"}
                      variant="filled"
                      size="small"
                    />
                    <Chip
                      sx={{ borderRadius: "8px" }}
                      label={"Member"}
                      variant="filled"
                      size="small"
                    />
                  </Stack>
                )}
              </Stack>
            </>
          </div>
        );
      },
    },
    {
      title: "Use Per Customer",
      accessor: "use_per_customer",
      hidden: (val) => {
        return val.customer_type !== 1
      }
    },
    {
      title: (val) => {
        return `Use Per Day Per ${val.customer_type === 1 ? "Customer" : "Coupon"}`
      },
      accessor: "use_per_day",
    },
    {
      title: (val) => {
        return `Time Lock Period Per ${val.customer_type === 1 ? "Customer" : "Coupon"}`
      },
      accessor: "time_lock_period",
    },
    {
      title: "Number of Coupon",
      accessor: "num_of_coupons",
      hidden: (val) => {
        return val.customer_type === 1 || val.coupon_type !== 1
      }
    },
    {
      title: (val) => {
        return `Maximum Redeem Count Per ${val.customer_type === 1 ? "Customer" : "Coupon"}`
      },
      accessor: "max_redeem_count",
    },
    {
      title: "Coupon Type",
      header: () => {
        return (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Coupons Type
              </Typography>
            </Stack>
            <Divider />
          </>
        );
      },
      accessor: (row) => {
        return (
          <div>
            <>
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack>
                  {
                    globalData?.coupons?.coupon_type?.find(
                      (i) => i.value === row.coupon_type
                    )?.label
                  }
                </Stack>
                <>
                  <Typography color={"#C07302"}>
                    {state.data.coupon_type === 2 ? state.data.coupon_code : ""}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => navigate(`/coupons/details/view/${id}`)}
                  >
                    View Coupons
                  </Button>
                </>
              </Stack>
            </>
          </div>
        );
      },
    },
    {
      title: "Discount Type & Amount",
      header: () => {
        return (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Action Type
              </Typography>
            </Stack>
            <Divider />
          </>
        );
      },
      accessor: (row) => {
        return (
          <div>
            <>
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack>
                  {
                    globalData?.coupons?.discount_type?.find(
                      (i) => i.value === row.discount_type
                    )?.label
                  }
                </Stack>
                <Stack>
                  <Typography color={"#C07302"}>
                    {state.data.discount_amount}{" "}
                    {state.data.discount_type === 2 ? "%" : "$"}
                  </Typography>
                </Stack>
              </Stack>
            </>
          </div>
        );
      },
    },
    {
      title: "Max. Discount Amount",
      accessor: "max_discount_amount",
    },
    {
      title: "Rule Type",
      header: () => {
        return (
          <>
            {" "}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="83px"
              p="32px 24px"
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Rules
              </Typography>
            </Stack>
            <Divider />
          </>
        );
      },
      accessor: (row) => {
        return (
          <div>
            <>
              <Stack direction="row" spacing={1}>
                <Stack>
                  {" "}
                  {
                    conditionType.find((i) => i.value === row.condition_type)
                      ?.label
                  }
                </Stack>
              </Stack>
            </>
          </div>
        );
      },
    },
    {
      title: "Rules",
      accessor: (row) => {
        const rules = row.conditions;
        return (
          <div>
            <>
              <Box>
                {rules?.map((data) => {
                  return (
                    <Chip
                      sx={{ mr: 1, borderRadius: "8px" }}
                      label={data.name}
                      variant="filled"
                      size="small"
                      onClick={() => navigate(`/coupon-rules/edit/${data.id}`)}
                    />
                  );
                })}
              </Box>
            </>
          </div>
        );
      },
    },
    {
      title: "Maximum Product Qty",
      accessor: "applicable_max_product_qty",
      hidden: (val) => {
        return !val.conditions.some(v => v.scope == 3)
      }
    },
  ];

  return (
    <>
      {state.isBusy ? (
        <Loader />
      ) : (
        <>
          <CustomDetailsList
            headerRightContent={
              <>
                {isEditable && (
                  <Button
                    type="button"
                    size="medium"
                    variant="text"
                    flex-direction="right"
                    onClick={() => navigate(`/coupons/edit/${id}`)}
                  >
                    Edit
                  </Button>
                )}
              </>
            }
            columns={column1}
            data={state.data}
            title="Details"
          />
        </>
      )}
    </>
  );
};
